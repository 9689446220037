<div class="row container" style="margin:0 auto;">
  <div class="col-md-6 grid-margin stretch-card">
    <div class="card">
      <a href="/avatar-framer"><img class="card-img-top" src="/assets/images/avatar-frame.png" alt="card images"></a>
      <!-- <img style="width:50%;" src="/assets/images/background.jpg" alt=""> -->
      <div class="card-body pb-0">
        <p>Tạo avatar theo khung hình Đại Hội Đại Biểu Hội Nông Dân Tỉnh Đồng Nai Lần Thứ X, Nhiệm Kỳ 2023 - 2028</p>
        <div style="text-align: center;"
          class="align-items-center justify-content-between text-muted border-top py-3 mt-3">
          <a href="/avatar-framer" class="btn btn-gradient-primary btn-fw" title="crop image">
            Tạo avatar
          </a>
        </div>
      </div>
    </div>

  </div>
  <div class="col-md-6 grid-margin stretch-card">
    <div class="card">
      <a href="/convert-img"><img class="card-img-top" src="/assets/images/background_kyvong.png" alt="card images"></a>
      <!-- <img style="width:50%;" src="/assets/images/background.jpg" alt=""> -->
      <div class="card-body pb-0">
        <!-- <h4><a href="/convert-img" class="text-danger">GỬI KỲ VỌNG</a></h4> -->
        <p>Gửi kỳ vọng đến Đại Hội Đại Biểu Hội Nông Dân Tỉnh Đồng Nai Lần Thứ X, Nhiệm Kỳ 2023 - 2028</p>
        <div style="text-align: center;"
          class="align-items-center justify-content-between text-muted border-top py-3 mt-3">
          <a href="/convert-img" class="btn btn-gradient-primary btn-fw" title="crop image">
            Gửi kỳ vọng
          </a>
        </div>
      </div>
    </div>

  </div>

</div>
