export const IP = 'app.mttqvn.iloka.vn';
// export const IP = 'localhost';
export const PORT = '8001'

export class AppConstants {
    private static API_BASE_URL = 'https://' + IP + ':' + PORT + '/';
    private static API_BASE_URL2 = 'https://' + IP + ':' + PORT;
    public static API_URL = AppConstants.API_BASE_URL + "api/";
    public static API_URL2 = AppConstants.API_BASE_URL2;
    public static AUTH_API = AppConstants.API_URL + "auth/";
    public static IP_PORT = IP + ':' + PORT;
}